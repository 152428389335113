html, body, #root {
  height: 100%;
  font-family: "Lato", Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: "Lato";
  font-display: swap;
  src: url(/src/assets/fonts/lato-v23-latin-regular.woff2) format("woff2");
}
